import React from 'react';
import ErrorBoundaryToast from '@lobox/uikit/ErrorBoundary/ErrorBoundaryToast';
import InvitePeopleModalComponent from './InvitePeople.modal';
import type { InvitePeopleModalProps } from './InvitePeople.modal';

const InvitePeopleModal = (props: InvitePeopleModalProps): JSX.Element => (
  <ErrorBoundaryToast>
    <InvitePeopleModalComponent {...props} />
  </ErrorBoundaryToast>
);

export default InvitePeopleModal;
