import classes from './ProfileHeader.Background.actions.module.scss';

import React from 'react';
import Flex from '@lobox/uikit/Flex';
import useTheme from '@lobox/uikit/utils/useTheme';
import {
  deviceWidth,
  Endpoints,
  fileApi,
  useReactMutation,
  useTranslation,
} from '@lobox/utils';
import { useProfile, useProfilePage } from '@shared/hooks';
import { setHeaderBackground, setPageHeaderBackground } from './header.utils';
import HeaderImageActions from './ProfileHeader.ImageActions';

interface HeaderBackgroundActionsProps {
  imgSrc?: string;
  originalImg?: string;
  croppedImageData: {
    rotate?: number;
    zoom?: number;
    position?: { x: number; y: number };
  };
}

const ProfileHeaderBackgroundActions: React.FC<
  HeaderBackgroundActionsProps
> = ({ imgSrc, originalImg, croppedImageData }) => {
  const { t } = useTranslation();
  const { reFetchAboutSectionQuery } = useProfile();
  const {
    theme: {
      variables: { contentMaxWidth },
    },
  } = useTheme();
  const visibleBlurBg = !!imgSrc && contentMaxWidth < deviceWidth();
  const { isPage, objectDetail, reFetchPageDetail } = useProfilePage();
  const cropWidth = 980;
  const cropHeight = 212;
  const pageId = objectDetail?.id;

  const { mutate: setUserAvatarHandler } = useReactMutation({
    apiFunc: setHeaderBackground,
  });

  const { mutate: setPageBackgroundHandler } = useReactMutation({
    apiFunc: setPageHeaderBackground,
  });

  const onSuccess = (
    { originalResponse, croppedResponse }: any,
    variables: any
  ) => {
    const croppedHeaderImageData = JSON.stringify(
      variables?.image?.croppedData || {}
    );

    if (isPage) {
      setPageBackgroundHandler(
        {
          pageId,
          headerImageUrl: originalResponse?.data?.link,
          croppedHeaderImageUrl: croppedResponse?.data?.link,
          croppedHeaderImageData,
        },
        {
          onSuccess: () => {
            reFetchPageDetail();
          },
        }
      );
    } else {
      setUserAvatarHandler(
        {
          headerImageLink: originalResponse?.data?.link,
          croppedHeaderImageLink: croppedResponse?.data?.link,
          croppedHeaderImageData,
        },
        {
          onSuccess: reFetchAboutSectionQuery as any,
        }
      );
    }
  };

  const onSuccessDelete = () => {
    reFetchAboutSectionQuery();
    reFetchPageDetail();
  };

  return (
    <Flex className={classes.coverHover}>
      <Flex className={classes.imageIconContainer}>
        <HeaderImageActions
          {...{
            imgSrc,
            originalImg,
            onSuccess,
            onSuccessDelete,
            apiFunc: fileApi.uploadCroppedImage,
            url: isPage
              ? Endpoints.App.Page.setHeaderBackground(pageId)
              : Endpoints.App.User.Update.cover,
            placement: 'bottom-end',
            className: classes.imageIcon,
            cropShape: 'rect',
            cropSize: {
              width: cropWidth,
              height: cropHeight,
            },
            type: 'background',
            croppedImageData,
            trySizeRatio: 1,
            labels: {
              deleteHint: t('delete_cover_image_hint'),
              save: t('save'),
              view: t('view_cover_image'),
              edit: t('edit_cover_image'),
              delete: t('delete_cover_image'),
              upload: t('upload_another'),
              deleteBtn: t('delete'),
              editImage: t('edit_cover_image'),
              viewImage: t('view_cover_image'),
              deleteImage: t('delete_cover_image'),
            },
          }}
        />
      </Flex>
    </Flex>
  );
};

export default React.memo(ProfileHeaderBackgroundActions);
