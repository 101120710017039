import classes from './CropImage.modal.module.scss';

import React, { useEffect, useRef, useState } from 'react';
import Button from '@lobox/uikit/Button';
import DynamicFormBuilder from '@lobox/uikit/Form/DynamicFormBuilder';
import Form from '@lobox/uikit/Form';
import SubmitButton from '@lobox/uikit/Form/SubmitButton';
import useMedia from '@lobox/uikit/utils/useMedia';
import ProgressBar from '@lobox/uikit/ProgressBar';
import Flex from '@lobox/uikit/Flex';
import random from 'lodash/random';
import {
  ModalBody,
  ModalDialog,
  ModalFooter,
  ModalHeaderSimple,
} from '../../molecules/Modal';

export interface CropImageModalFormProps {
  openPicker?: () => void;
  image?: {
    original: File;
    base64?: string;
  };
  showConfirm?: boolean;
  title?: string;
  onClose: () => void;
  groups?: Array<object>;
  onSuccess?: (a: any, b?: any, c?: any) => any;
  // hideFilePicker?: boolean;
  primaryAction: { label: string; active?: boolean };
  secondaryAction?: { label?: string };
  url?: string;
  method: 'POST' | 'DELETE';
  transform?: Function;
  headers?: any;
  apiFunc?: Function;
}
const CropImageModalForm = ({
  openPicker,
  showConfirm = true,
  title,
  onClose,
  primaryAction,
  secondaryAction,
  url,
  onSuccess,
  groups,
  method,
  transform,
  image,
  headers,
  apiFunc,
}: CropImageModalFormProps): JSX.Element => {
  const timeoutIds = useRef<NodeJS.Timeout[]>([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [progress, setProgress] = useState(0);
  const { isMoreThanTablet } = useMedia();

  const onFailure = () => {
    setProgress(0);
  };

  const hackProgress = (currentProgress: number) => {
    const rand = random(4, 8);
    const pice = currentProgress / rand;
    for (let ii = 0; ii < rand; ii += 1) {
      timeoutIds.current.push(
        setTimeout(() => {
          setProgress((prog) => {
            const newProgress = prog + pice;
            return newProgress < 100 ? newProgress : prog;
          });
        }, 500 * ii)
      );
    }
  };

  useEffect(() => {
    hackProgress(uploadProgress);
  }, [uploadProgress]);

  const onSuccessfulProcess = (data: any, variables: any, formRef: any) => {
    timeoutIds.current.forEach((id) => clearTimeout(id));
    timeoutIds.current = [];
    setProgress(() => 100);
    setTimeout(() => {
      onSuccess(data, variables, formRef);
    }, 500);
  };

  return (
    <Form
      key={image?.base64}
      transform={transform}
      initialValues={{ image }}
      onSuccess={onSuccessfulProcess}
      onFailure={onFailure}
      url={url}
      method={method}
      headers={headers}
      apiFunc={(props: any) =>
        apiFunc?.({ ...props, onProgress: setUploadProgress })
      }
    >
      {() => (
        <ModalDialog
          showConfirm={showConfirm}
          isOpen
          onClose={onClose}
          onBack={onClose}
          contentClassName={classes.contentClassName}
        >
          <ProgressBar
            progressClassName={classes.progressClassName}
            className={classes.progressbar}
            value={progress}
          />
          <ModalHeaderSimple title={title} hideBack={isMoreThanTablet} />
          <ModalBody className={classes.modalBody}>
            <DynamicFormBuilder groups={groups as any} />
          </ModalBody>
          <ModalFooter className={classes.submitWrap}>
            {!!secondaryAction && (
              <Button
                schema="ghost"
                labelFont="bold"
                labelColor="primaryText"
                onClick={openPicker}
                className={classes.deleteButton}
                label={secondaryAction?.label}
              />
            )}
            <SubmitButton
              schema="primary-blue"
              labelFont="bold"
              className={classes.submitButton}
              labelClassName={classes.labelStyle}
              active
              label={primaryAction?.label}
            />
          </ModalFooter>
          {progress > 0 && <Flex className={classes.overlay} />}
        </ModalDialog>
      )}
    </Form>
  );
};

export default CropImageModalForm;
