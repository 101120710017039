import classes from './MoreButton.module.scss';

import React, { forwardRef } from 'react';
import Icon from '../Icon';
import cnj from '../utils/cnj';
import Flex from '../Flex';

interface MoreButtonProps {
  isActive?: boolean;
  label?: string;
}

const MoreButton = (
  { isActive, label, ...rest }: MoreButtonProps,
  ref: any
) => {
  return (
    <Flex
      as="span"
      {...rest}
      ref={ref}
      className={cnj(classes.moreButton, isActive && classes.moreButtonActive)}
    >
      <Flex
        as="span"
        className={cnj(classes.text, isActive && classes.moreButtonActive)}
      >
        {label}
        <Icon
          name="chevron-down"
          size={10}
          type={isActive ? 'fas' : 'far'}
          className={classes.icon}
        />
      </Flex>
    </Flex>
  );
};

export default forwardRef<any, MoreButtonProps>(MoreButton);
