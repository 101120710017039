import React from 'react';
import ErrorBoundaryToast from '@lobox/uikit/ErrorBoundary/ErrorBoundaryToast';
import CropImageModal from './CropImage.modal';
import type { CropImageModalFormProps } from './CropImage.modal';

const CropImageModalForm = (props: CropImageModalFormProps): JSX.Element => (
  <ErrorBoundaryToast>
    <CropImageModal {...props} />
  </ErrorBoundaryToast>
);

export default CropImageModalForm;
