import classes from './ProfileHeader.UserInfo.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import OverflowTip from '@lobox/uikit/Typography/OverflowTip';
import Typography from '@lobox/uikit/Typography';
import useMedia from '@lobox/uikit/utils/useMedia';
import { useIsPageCreation, useTranslation } from '@lobox/utils';
import { FollowersAndFollowings } from '@shared/components/molecules';
import FollowingYouIcon from '@shared/components/molecules/FollowingYouIcon';
import type { NetworkModalObjectDataType } from '@shared/types';
import dynamic from 'next/dynamic';

const MemberSince = dynamic(() => import('./MemberSince'));

interface UserInfoProps {
  exactName: string;
  job: string;
  networkModalObject: NetworkModalObjectDataType;
  usernameAtSign: string;
  disabled: boolean;
  isBusinessApp: boolean;
  onClickPosts?: () => void;
}

const UserInfo = ({
  exactName,
  job,
  usernameAtSign,
  disabled,
  networkModalObject,
  isBusinessApp,
  onClickPosts,
}: UserInfoProps): JSX.Element => {
  const isPageCreation = useIsPageCreation();
  const { isMoreThanTablet } = useMedia();
  const { t } = useTranslation();

  return (
    <Flex className={classes.userInfoContainer}>
      <Flex>
        <OverflowTip color="thirdText" size={25} height={29} font="700">
          {exactName}
        </OverflowTip>
        <Flex className={classes.userNameWrapper}>
          <Typography color="border" size={16} height={19} isTruncated mr={8}>
            {usernameAtSign}
          </Typography>
          {!!networkModalObject?.network?.back && <FollowingYouIcon />}
        </Flex>
      </Flex>
      <Typography size={16} height={19} mt={4} isTruncated>
        {t(job)}
      </Typography>
      <Flex
        className={cnj(
          classes.followersInfoWrapper,
          isPageCreation && classes.followersInfoWrapperHidden
        )}
      >
        <FollowersAndFollowings
          networkModalObject={networkModalObject}
          disabled={disabled}
          onClickPosts={onClickPosts}
          variant={isMoreThanTablet ? 'row' : 'col'}
        />
        {!isBusinessApp && <MemberSince className={classes.memberSince} />}
      </Flex>
    </Flex>
  );
};

export default UserInfo;
