import classes from './ProfileLayout.ActionButtons.module.scss';

import React from 'react';
import Button from '@lobox/uikit/Button';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import useTheme from '@lobox/uikit/utils/useTheme';
import { useParams } from '@lobox/utils';
import { PAGE_ROLES, useAuthState, useTranslation } from '@lobox/utils';
import { followStatus, pageStatus } from '@shared/constants/enums';
import PendingButton from '@shared/components/molecules/PendingButton/PendingButton';
import { useProfilePage } from '@shared/hooks';
import urls from '@shared/constants/urls';
import openBusinessApp from '@shared/utils/openBusiness';
import { FollowButton, FollowingButton } from '@shared/components/molecules';
import useGetAppObject from '@shared/hooks/useGetAppObject';
import { isBusinessApp } from '@shared/utils/getAppEnv';
import useHasPermission from '@shared/hooks/useHasPermission';
import { SCOPES } from '@shared/constants/userRoles.scopes';
import useGetRole from '@shared/hooks/useGetRole';
import SendMessageButton from '@shared/components/molecules/SendMessageButton';
import type { QueryParamsType } from '@lobox/utils';
import UnBlockButton from './UnBlockButton';

interface ProfileLayoutActionsProps {
  className?: string;
  blockedAccount: boolean;
}

const ProfileLayoutActions = ({
  className,
  blockedAccount,
}: ProfileLayoutActionsProps): JSX.Element => {
  const {
    isAuthUser,
    objectDetail,
    isPageCreation,
    isPage,
    isAuthBusinessPage,
    hasMembership,
    network,
    reFetchPageDetail,
    getObjectProp,
    isLoading,
    isPrivateAccount,
  } = useProfilePage();
  const { reFetchAppObject } = useGetAppObject();
  const { username } = useParams<QueryParamsType>();
  const { t } = useTranslation();
  const { isDark } = useTheme();
  const isLoggedIn = useAuthState('isLoggedIn');
  const canSeeMessagesScreen = useHasPermission([SCOPES.canSeeMessagesScreen]);
  const canFollowUnFollow = useHasPermission([SCOPES.canFollowUnFollow]);
  const { roles } = useGetRole();
  const isRecruiter = roles.includes(PAGE_ROLES.RECRUITER.value);
  const objectName = getObjectProp({ userKey: 'fullName', pageKey: 'title' });
  const following = network?.follow;
  const pending = network?.followStatus === followStatus.PENDING;
  const visibleMessageButton =
    (canSeeMessagesScreen && !isRecruiter) || (isRecruiter && !isPage);

  const visibleFollowActions = canFollowUnFollow;
  const isPagePublished = objectDetail?.status === pageStatus.PUBLISHED;
  const openBusiness = () => openBusinessApp(objectDetail?.username);

  const onFollowSuccess = () => {
    reFetchPageDetail();
    reFetchAppObject();
  };

  if (isLoading) {
    return null;
  }

  if (isLoggedIn && !isBusinessApp && hasMembership) {
    return (
      <Flex className={cnj(classes.tabBarAddSectionBtn, className)}>
        <Flex flexDir="row" className={classes.buttonsWrap}>
          {isPagePublished && (
            <Button
              label={t('switch_to_business')}
              schema={isDark ? 'secondary-dark' : 'semi-transparent'}
              onClick={openBusiness}
              className={cnj(
                classes.flexOne,
                classes.marginRightResponsiveGutter
              )}
            />
          )}
          {following ? (
            <FollowingButton
              className={cnj(classes.flexOne, classes.followingButton)}
              object={{
                isPage,
                id: objectDetail?.id,
                username: objectDetail.username,
                name: objectName as string,
              }}
              onSuccess={onFollowSuccess}
            />
          ) : isPagePublished ? (
            <FollowButton
              back={objectDetail?.network?.back}
              schema="primary-blue"
              object={{
                id: objectDetail?.id,
                isPage,
                username: objectDetail.username,
              }}
              onSuccess={onFollowSuccess}
              className={cnj(classes.flexOne, classes.followButton)}
            />
          ) : null}
        </Flex>
      </Flex>
    );
  }

  if (isLoggedIn && blockedAccount) {
    return <UnBlockButton className={className} />;
  }

  if (isPageCreation || isAuthBusinessPage) {
    return null;
  }

  if (!isLoggedIn) {
    return (
      <Flex className={cnj(classes.tabBarAddSectionBtn, className)}>
        <Button
          label={t('login_to_follow')}
          leftIcon="sign-in-alt"
          href={`${urls.login}?redirect=/${username}`}
        />
      </Flex>
    );
  }

  if (isPage) {
    return (
      <Flex className={cnj(classes.tabBarAddSectionBtn, className)}>
        <Flex flexDir="row" className={classes.buttonsWrap}>
          {visibleMessageButton && (
            <SendMessageButton
              className={cnj(
                classes.flexOne,
                classes.marginRightResponsiveGutter
              )}
              object={{
                isPage,
                username: objectDetail.username,
                croppedImageUrl: objectDetail.croppedImageUrl,
                fullName: objectDetail.fullName,
                id: objectDetail.id,
              }}
            />
          )}
          {following ? (
            <FollowingButton
              className={cnj(classes.flexOne, classes.followingButton)}
              object={{
                isPage,
                id: objectDetail?.id,
                username: objectDetail.username,
                name: objectName as string,
              }}
              onSuccess={onFollowSuccess}
            />
          ) : (
            <FollowButton
              back={objectDetail?.network?.back}
              schema="primary-blue"
              object={{
                id: objectDetail?.id,
                isPage,
                username: objectDetail.username,
              }}
              onSuccess={onFollowSuccess}
              className={cnj(classes.flexOne, classes.followButton)}
            />
          )}
        </Flex>
      </Flex>
    );
  }

  if (isAuthUser && !isBusinessApp) {
    return null;
  }

  if (
    (isLoggedIn && !isAuthUser) ||
    (isLoggedIn && isAuthUser && isBusinessApp)
  ) {
    return (
      <Flex className={cnj(classes.tabBarAddSectionBtn, className)}>
        <Flex flexDir="row" className={classes.buttonsWrap}>
          {canSeeMessagesScreen && !isPrivateAccount && (
            <SendMessageButton
              className={cnj(
                classes.flexOne,
                classes.marginRightResponsiveGutter
              )}
              object={{
                isPage,
                username: objectDetail.username,
                croppedImageUrl: objectDetail.croppedImageUrl,
                fullName: objectDetail.fullName,
                id: objectDetail.id,
              }}
              fullWidth
            />
          )}
          {visibleFollowActions && (
            <>
              {pending ? (
                <PendingButton
                  object={{
                    id: objectDetail?.id,
                    username: objectDetail?.username,
                  }}
                  onSuccess={reFetchPageDetail}
                />
              ) : following ? (
                <FollowingButton
                  className={cnj(classes.flexOne, classes.followingButton)}
                  object={{
                    isPage,
                    id: objectDetail?.id,
                    username: objectDetail.username,
                    name: objectName as string,
                  }}
                  onSuccess={onFollowSuccess}
                />
              ) : (
                <FollowButton
                  back={objectDetail?.network?.back}
                  schema="primary-blue"
                  object={{
                    id: objectDetail?.id,
                    isPage,
                    username: objectDetail.username,
                  }}
                  onSuccess={onFollowSuccess}
                  className={cnj(classes.flexOne, classes.followButton)}
                />
              )}
            </>
          )}
        </Flex>
      </Flex>
    );
  }

  return null;
};

export default React.memo(ProfileLayoutActions);
