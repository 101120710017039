import classes from './index.module.scss';

import React, { useState, useRef, useEffect } from 'react';
import PopperMenu from '@lobox/uikit/PopperMenu';
import IconButton from '@lobox/uikit/Button/IconButton';
import PopperItem from '@lobox/uikit/PopperItem';
import FilePicker from '@lobox/uikit/FilePicker';
import Flex from '@lobox/uikit/Flex';
import {
  imageUrlToBlobAndBase64,
  convertBlobToFile,
  webifyImage,
} from '@lobox/utils';
import CropImageModalForm from '../../../../../Organism/CropImageModalForm';
import ViewImage from './ViewImage';

type Size = {
  width: number;
  height: number;
};

interface HeaderImageActionsProps {
  offsetY?: number;
  imgSrc?: string;
  originalImg?: string;
  className?: string;
  cropShape?: 'round' | 'rect';
  type?: 'avatar' | 'background';
  trySizeRatio?: number;
  cropSize?: Size;
  croppedImageData: {
    rotate?: number;
    zoom?: number;
    position?: { x: number; y: number };
  };
  labels: {
    edit: string;
    view: string;
    delete: string;
    save: string;
    upload: string;
    deleteBtn: string;
    deleteHint?: string;
    deleteImage?: string;
    editImage?: string;
    viewImage?: string;
  };
  url?: string;
  onSuccess?: (a: any, b?: any, c?: any) => any;
  onSuccessDelete?: (a: any, b?: any, c?: any) => any;
  placement?: string;
  apiFunc?: Function;
}

const HeaderImageActions: React.FC<HeaderImageActionsProps> = ({
  offsetY,
  placement,
  url,
  imgSrc,
  className,
  cropSize,
  type,
  cropShape,
  croppedImageData,
  trySizeRatio = 2,
  labels,
  onSuccess: onSuccessHandler,
  onSuccessDelete,
  originalImg,
  apiFunc,
}) => {
  const newFilePicked = useRef(false);
  const pickerRef = useRef<{ click: Function }>(null);
  const [visibleView, setVisibleView] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [image, setImage] = useState<{
    original: File;
    base64?: string;
  } | null>();

  const editHandler = async () => {
    const data = await imageUrlToBlobAndBase64(originalImg as string);
    setImage(() => ({
      original: convertBlobToFile(data.blob) /* , base64: data.base64 */,
    }));
  };
  const openPicker = () => pickerRef?.current?.click();
  const viewHandler = () => setVisibleView(true);
  const deleteHandler = () => setVisibleDelete(true);

  const onSuccess = (data: any, variables: any, formRef: any) => {
    setImage(() => null);
    setVisibleDelete(false);
    onSuccessHandler?.(data, variables, formRef);
    formRef.resetForm();
  };

  const onFilePicked = (file: File) => {
    webifyImage({
      file,
      cropWidth: cropSize.width,
      cropHeight: cropSize.height,
      outputQuality: 99,
      trySizeRatio,
    }).then((original: File) => {
      webifyImage({
        file: original,
        outputType: 'base64',
        trySizeRatio,
      }).then((base64: string) => {
        setImage(() => ({ original, base64 }));
      });
    });

    newFilePicked.current = true;
  };

  useEffect(() => {
    if (!image) {
      newFilePicked.current = false;
    }
  }, [image]);

  return (
    <>
      <Flex className={className}>
        {imgSrc ? (
          <PopperMenu
            closeOnScroll
            // offsetY={offsetY}
            placement={placement}
            // disablePortal
            buttonComponent={
              <IconButton type="fas" name="images" className={classes.icon} />
            }
          >
            <PopperItem
              iconName="image"
              onClick={viewHandler}
              label={labels?.view}
            />
            <PopperItem
              iconName="pen"
              onClick={editHandler}
              label={labels?.edit}
            />
            <PopperItem
              iconName="trash"
              onClick={deleteHandler}
              label={labels?.delete}
            />
          </PopperMenu>
        ) : (
          <IconButton
            onClick={openPicker}
            type="fas"
            name="images"
            className={classes.icon}
          />
        )}
      </Flex>
      {!!image && (
        <CropImageModalForm
          {...{
            openPicker,
            image,
            method: 'POST',
            onSuccess,
            title: labels?.editImage,
            onClose: () => setImage(() => null),
            primaryAction: { label: labels?.save },
            secondaryAction: { label: labels?.upload },
            apiFunc,
            groups: [
              {
                name: 'image',
                cp: 'imageCropper',
                cropShape,
                cropSize,
                type,
                croppedImageData: newFilePicked.current
                  ? {
                      zoom: 0,
                      rotate: 0,
                      position: { x: 0, y: 0 },
                    }
                  : croppedImageData,
              },
            ],
          }}
        />
      )}
      {visibleDelete && (
        <CropImageModalForm
          {...{
            showConfirm: false,
            hideFilePicker: true,
            url,
            method: 'DELETE',
            onSuccess: (data) => {
              onSuccessDelete?.(data);
              setVisibleDelete(false);
            },
            title: labels?.deleteImage,
            onClose: () => setVisibleDelete(false),
            primaryAction: { label: labels?.deleteBtn, active: true },
            groups: [
              {
                name: 'image',
                cp: 'imageDelete',
                hint: labels?.deleteHint,
                image: imgSrc,
              },
            ],
          }}
        />
      )}
      {visibleView && (
        <ViewImage
          image={imgSrc}
          onClose={() => setVisibleView(false)}
          title={labels?.viewImage}
        />
      )}
      <FilePicker
        // @ts-ignore
        ref={pickerRef}
        onFilePicked={onFilePicked}
        type="image"
      />
    </>
  );
};

export default HeaderImageActions;
