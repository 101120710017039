import React, { forwardRef, useRef } from 'react';
import { useLocation } from '@lobox/utils';
import cnj from '../utils/cnj';
import useHorizontalScroll from '../utils/useHorizontalScroll';
import PopperMenu from '../PopperMenu';
import NavLink from './NavLink';
import NavLinkPopper from './NavLinkPopper';
import MoreButton from './MoreButton';
import Skeleton from '../Skeleton/Skeleton';
import Flex from '../Flex';
import DividerVertical from '../Divider/DividerVertical';
import classes from './index.module.scss';

interface TabsProps {
  className?: string;
  innerClassName?: string;
  linksRootClassName?: string;
  navLinkClassName?: string;
  navLinkActiveClassName?: string;
  dividerClassName?: string;
  contentRootClassName?: string;
  linkAndActionWrapperClassName?: string;
  tabsWrapperClassName?: string;
  tabs: Array<{
    title: string;
    path: string;
    width?: number;
    exact?: boolean;
    disabled?: boolean;
    hasLeftDivider?: boolean;
    badge?: string;
  }>;
  children?: React.ReactNode;
  actionButton?: React.ReactNode;
  precedingActionButton?: React.ReactNode;
  isLoading?: boolean;
  moreLabel?: string;
  leftSideSection?: any;
  isFullWidth?: boolean;
  linkVariant?: 'underline' | 'badge';
  tabsShowLimit?: number;
  linksRootProps?: Record<any, any>;
}

const TABS_SHOW_LIMIT = 5;

const Tabs = (
  {
    className,
    innerClassName,
    linksRootClassName,
    navLinkClassName,
    navLinkActiveClassName,
    dividerClassName,
    contentRootClassName,
    linkAndActionWrapperClassName,
    tabsWrapperClassName,
    tabs,
    children,
    actionButton,
    isLoading,
    moreLabel,
    leftSideSection,
    isFullWidth = true,
    linkVariant = 'underline',
    precedingActionButton,
    tabsShowLimit = TABS_SHOW_LIMIT,
    linksRootProps = {},
  }: TabsProps,
  ref: any
) => {
  const linkWrapperRef = useRef<HTMLElement>();
  const { pathname } = useLocation();
  const tabsLength = tabs.length;
  const doesHaveMoreButton = tabsLength > tabsShowLimit + 1;
  const tabsToShow = tabs.slice(0, tabsShowLimit);
  const tabsInPopper = tabs.slice(tabsShowLimit);
  const isMoreButtonActive = tabsInPopper.some(({ path }) => path === pathname);
  const isBadge = linkVariant === 'badge';

  useHorizontalScroll(linkWrapperRef, 1);

  return (
    <Flex ref={ref} className={cnj(classes.tabsRoot, className)}>
      <Flex className={cnj(classes.innerWrapper, innerClassName)}>
        <Flex
          className={cnj(classes.linksRoot, linksRootClassName)}
          {...linksRootProps}
        >
          {leftSideSection}
          <Flex
            className={cnj(
              classes.linkAndActionWrapper,
              linkAndActionWrapperClassName
            )}
          >
            {isLoading ? (
              <>
                {[1, 2, 3, 4].map((i) => (
                  <Skeleton key={i} className={classes.skeleton} />
                ))}
              </>
            ) : (
              <>
                {precedingActionButton}
                <Flex
                  className={cnj(
                    classes.buttonsWrapper,
                    isBadge && classes.badgeButtonsWrapper,
                    tabsWrapperClassName
                  )}
                  ref={linkWrapperRef}
                >
                  {tabsToShow.map(
                    (
                      {
                        title,
                        path,
                        width,
                        exact,
                        disabled,
                        hasLeftDivider,
                        badge,
                      },
                      index
                    ) =>
                      hasLeftDivider ? (
                        <Flex
                          className={classes.navLinkWrapper}
                          key={path}
                          flexDir="row"
                        >
                          <DividerVertical
                            className={cnj(classes.divider, dividerClassName)}
                          />
                          <NavLink
                            isFullWidth={isFullWidth && tabs?.length > 1}
                            className={navLinkClassName}
                            activeClassName={navLinkActiveClassName}
                            title={title}
                            path={path}
                            exact={exact}
                            width={width}
                            disabled={disabled}
                            isBadge={isBadge}
                            badge={badge}
                            isFirstLink={index === 0}
                          />
                        </Flex>
                      ) : (
                        <NavLink
                          className={cnj(
                            classes.navLinkWrapper,
                            navLinkClassName
                          )}
                          activeClassName={navLinkActiveClassName}
                          isFullWidth={isFullWidth && tabs?.length > 1}
                          key={path}
                          title={title}
                          path={path}
                          exact={exact}
                          width={width}
                          disabled={disabled}
                          isBadge={isBadge}
                          badge={badge}
                          isFirstLink={index === 0}
                        />
                      )
                  )}
                  {tabsInPopper.map(
                    ({ title, path, width, exact, disabled }, index) => (
                      <NavLink
                        className={cnj(
                          doesHaveMoreButton && classes.tabsInMobileView,
                          navLinkClassName
                        )}
                        key={path}
                        title={title}
                        path={path}
                        exact={exact}
                        width={width}
                        disabled={disabled}
                        isBadge={isBadge}
                        isFirstLink={index === 0}
                      />
                    )
                  )}
                  {doesHaveMoreButton && (
                    <PopperMenu
                      closeOnScroll
                      closeDelay={0}
                      placement="bottom-start"
                      buttonComponent={
                        <MoreButton
                          isActive={isMoreButtonActive}
                          label={moreLabel}
                        />
                      }
                    >
                      {tabsInPopper.map(({ title, path }) => (
                        <NavLinkPopper path={path} title={title} />
                      ))}
                    </PopperMenu>
                  )}
                </Flex>
                {actionButton}
              </>
            )}
          </Flex>
        </Flex>
        {!!children && (
          <Flex className={cnj(classes.content, contentRootClassName)}>
            {children}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default forwardRef<any, TabsProps>(Tabs);
