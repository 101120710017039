import React from 'react';
import ErrorBoundaryToast from '@lobox/uikit/ErrorBoundary/ErrorBoundaryToast';
import ConfirmAndResetPasswordModalComponent from './ConfirmAndResetPasswordModal.modal';
import type { ConfirmAndResetPasswordModalProps } from './ConfirmAndResetPasswordModal.modal';

const ConfirmAndResetPasswordModal = (
  props: ConfirmAndResetPasswordModalProps
): JSX.Element => (
  <ErrorBoundaryToast>
    <ConfirmAndResetPasswordModalComponent {...props} />
  </ErrorBoundaryToast>
);

export default ConfirmAndResetPasswordModal;
