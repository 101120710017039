import classes from './ProfileHeader.Background.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import Image from '@lobox/uikit/Image';
import { useGlobalDispatch } from '@lobox/utils';
import { useProfilePage } from '@shared/hooks';
import useHasPermission from '@shared/hooks/useHasPermission';
import { SCOPES } from '@shared/constants/userRoles.scopes';
import urls from '@shared/constants/urls';
import ProfileHeaderBackgroundActions from './ProfileHeader.Background.actions';

interface HeaderBackgroundProps {
  headerRef: React.Ref<any>;
  imgSrc: string;
  originalImg?: string;
  disabled: boolean;
  isCompany: boolean;
  croppedImageData: {
    rotate?: number;
    zoom?: number;
    position?: { x: number; y: number };
  };
}

const ProfileHeaderBackground: React.FC<HeaderBackgroundProps> = ({
  headerRef,
  imgSrc,
  originalImg,
  disabled,
  croppedImageData,
  isCompany,
}) => {
  const dispatch = useGlobalDispatch();
  const isEmpty = !imgSrc;
  const { isAuthUser, isAuthBusinessPage } = useProfilePage();
  const placeHolderImage = isCompany
    ? urls.placeholderPage
    : urls.placeholderUser;

  const hasEditAccess = useHasPermission([SCOPES.canEditProfile]);
  const visibleBackgroundAction =
    isAuthUser || (isAuthBusinessPage && hasEditAccess);

  const onClickImageHandler = () => {
    if (imgSrc && !disabled) {
      dispatch({
        type: 'OPEN_VIEW_MEDIA',
        payload: { medias: [{ type: 'image', path: imgSrc, id: 1 }] },
      });
    }
  };

  return (
    <Flex className={classes.headerBackground} ref={headerRef}>
      <Image
        resolution="tiny"
        src={isEmpty ? placeHolderImage : imgSrc}
        className={classes.blurBg}
        alt="."
      />
      <Flex className={classes.blur} />
      {visibleBackgroundAction && (
        <ProfileHeaderBackgroundActions
          imgSrc={imgSrc}
          originalImg={originalImg}
          croppedImageData={croppedImageData}
        />
      )}
      {isEmpty ? (
        <Image
          className={classes.image}
          src={placeHolderImage}
          resolution="original"
          alt="lobox-default-cover"
        />
      ) : (
        <Image
          onClick={onClickImageHandler as any}
          className={cnj(classes.image, !disabled && classes.pointerCursor)}
          resolution="original"
          src={imgSrc}
          alt="lobox-header-cover"
        />
      )}
    </Flex>
  );
};

export default React.memo(ProfileHeaderBackground);
