import React from 'react';
import {
  deletePage,
  redirectUrl,
  removePageCookies,
  useReactMutation,
  useTranslation,
} from '@lobox/utils';
import env from '@shared/constants/env';
import useGetAppObject from '@shared/hooks/useGetAppObject';
import ConfirmPasswordModal from '../ConfirmPasswordModal';
import useConfirmPasswordFailure from '@shared/hooks/useConfirmPasswordFailure';

export interface ConfirmAndResetPasswordModalProps {
  setVisibleConfirmModal: any;
  visibleConfirmModal: any;
  id: string;
}

const ConfirmPasswordAndResetModal = ({
  setVisibleConfirmModal,
  visibleConfirmModal,
  id,
}: ConfirmAndResetPasswordModalProps): JSX.Element => {
  const { t } = useTranslation();
  const closeConfirmPassModal = () => setVisibleConfirmModal(false);
  const { authUser } = useGetAppObject();

  const { mutate: deletion } = useReactMutation({
    apiFunc: deletePage,
  });

  const { handleConfirmPasswordFailure } = useConfirmPasswordFailure();

  const handleDeletion = ({ password }: any, formRef: any) => {
    deletion(
      { password, id },
      {
        onSuccess: () => {
          removePageCookies();
          redirectUrl(`${env.baseUrl}/${authUser?.username}`);
        },
        onError: ({ response }: any) => {
          // @ts-ignore
          const errorKey = errorMsg[response?.data?.error];
          handleConfirmPasswordFailure(errorKey, formRef, {
            title: null,
            message: t(errorKey || 'unknown_error'),
          });
        },
      }
    );
  };

  return (
    <ConfirmPasswordModal
      isOpen={visibleConfirmModal}
      onClose={closeConfirmPassModal}
      onBack={closeConfirmPassModal}
      formProps={{
        local: true,
        onSuccess: handleDeletion,
      }}
    />
  );
};

export default ConfirmPasswordAndResetModal;
