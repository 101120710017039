import classes from './Footer.Desktop.module.scss';

import React from 'react';
import {
  APP_DESKTOP_FOOTER,
  Cookies,
  getCookieKey,
  landingRouteNames,
  useGlobalState,
  useTranslation,
} from '@lobox/utils';
import type { TFunction } from 'i18next';
import cnj from '../utils/cnj';
import Flex from '../Flex';
import Typography from '../Typography';
import LogoCopyRight from '../LogoCopyRight';
import LanguageDropDown from './LanguageDropDown';
import Link from '../Link';

interface FooterDesktopProps {
  className?: string;
  logoGray?: boolean;
  baseUrl: string;
}

const footerDataMaker = (t: TFunction) => [
  {
    key: 'help_center',
    title: t('help_center'),
    link: landingRouteNames.helpCenter,
    list: [
      {
        title: t('popular_topics'),
        link: landingRouteNames.helpCenter_popularTopics,
        key: 'popular_topics',
      },
      { title: t('faq'), link: landingRouteNames.helpCenter_faq, key: 'faq' },
      {
        title: t('feedback'),
        link: landingRouteNames.helpCenter_feedback,
        key: 'feedback',
      },
    ],
  },
  {
    key: 'privacy_security',
    title: t('privacy_security'),
    link: landingRouteNames.privacySecurity,
    list: [
      {
        title: t('user_agreement'),
        link: landingRouteNames.userAgreement,
        key: 'user_agreement',
      },
      {
        title: t('privacy'),
        link: landingRouteNames.privacy,
        key: 'privacy_terms',
      },
      {
        title: t('about_cookies'),
        link: landingRouteNames.aboutCookies,
        key: 'about_cookies',
      },
      {
        title: t('security'),
        link: landingRouteNames.security,
        key: 'security',
      },
    ],
  },
  {
    key: 'business_solutions',
    title: t('business_solutions'),
    link: landingRouteNames.businessSolutions,
    list: [
      {
        title: t('products'),
        link: landingRouteNames.businessSolutions_products,
        key: 'products',
      },
      {
        title: t('pricing'),
        link: landingRouteNames.businessSolutions_pricing,
        key: 'pricing',
      },
      {
        title: t('business_support'),
        link: landingRouteNames.businessSolutions_support,
        key: 'business_support',
      },
    ],
  },
  {
    key: 'about_us',
    title: t('about_us'),
    link: landingRouteNames.aboutUs,
    list: [
      {
        title: `${t('who_v_r')}?`,
        link: landingRouteNames.aboutUs_whoWeAre,
        key: 'our_story',
      },
      {
        title: t('our_values'),
        link: landingRouteNames.aboutUs_ourValues,
        key: 'career',
      },
      {
        title: t('career'),
        link: landingRouteNames.aboutUs_career,
        key: 'locations',
      },
      { title: t('team'), link: landingRouteNames.meetTheTeam, key: 'team' },
    ],
  },
];

const FooterDesktop: React.FC<FooterDesktopProps> = ({
  className,
  logoGray,
  baseUrl: _baseUrl,
}) => {
  const { t, changeLanguage, currentLanguage } = useTranslation();
  const footerData = footerDataMaker(t);
  const handleChangeLanguage = ({ value }: any) => {
    changeLanguage(value);
  };

  return (
    <Flex
      id={APP_DESKTOP_FOOTER}
      className={cnj(classes.rootFooter, className)}
    >
      <Flex className={classes.topFooter}>
        {footerData.map(({ key, title, list, link: lnk }) => (
          <Flex key={key} className={classes.footListWrapper}>
            <Flex>
              <Link to={lnk} className={classes.hover}>
                <Typography font="700" size={16} height={20} color="thirdText">
                  {title}
                </Typography>
              </Link>
            </Flex>
            <Flex className={classes.footListWrapper}>
              {list.map(({ key: k, title: ttl, link }: any) => (
                <Flex key={k}>
                  <Link
                    to={link}
                    className={cnj(classes.listTitle, classes.hover)}
                  >
                    <Typography
                      font="400"
                      color="fifthText"
                      size={14}
                      height={16.41}
                    >
                      {ttl}
                    </Typography>
                  </Link>
                </Flex>
              ))}
            </Flex>
          </Flex>
        ))}
        <Flex>
          <Typography font="400" className={classes.selectLanguage}>
            {t('select_lng')}
          </Typography>
          <LanguageDropDown
            onChange={handleChangeLanguage}
            currentLanguage={currentLanguage}
          />
        </Flex>
      </Flex>
      <Flex className={classes.bottomFooter}>
        <LogoCopyRight logoGray={logoGray} />
      </Flex>
    </Flex>
  );
};

export default FooterDesktop;
