import classes from './NavLinkPopper.module.scss';

import React from 'react';
import NavLink from './NavLink';
import Typography from '../Typography';
import cnj from '../utils/cnj';

interface NavLinkPopperProps {
  title?: string;
  path: string;
  className?: string;
}

const NavLinkPopper: React.FC<NavLinkPopperProps> = ({
  className,
  title,
  path,
}) => {
  return (
    <NavLink
      className={cnj(classes.navLinkPopperRoot, className)}
      to={path}
      activeClassName={classes.activeRoute}
    >
      <Typography font="400" size={15} height={21} color="thirdText">
        {title}
      </Typography>
    </NavLink>
  );
};
export default NavLinkPopper;
