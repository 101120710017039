import { useEffect } from 'react';
import { event } from '@lobox/utils';
import eventKeys from '@shared/constants/event-keys';
import { PROFILE_SCROLL_WRAPPER } from '@shared/constants/enums';

const useScrollToTopEventListener = () => {
  useEffect(() => {
    event.on(eventKeys.scrollToTopFeedList, () => {
      const container = document.getElementById(PROFILE_SCROLL_WRAPPER);
      if (!container) return;
      container.scrollTo({ top: 0 });
    });
    return () => event.off(eventKeys.scrollToTopFeedList);
  }, []);
};

export default useScrollToTopEventListener;
