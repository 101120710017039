import React from 'react';
import Flex from '@lobox/uikit/Flex';
import Icon from '@lobox/uikit/Icon';
import Typography from '@lobox/uikit/Typography';
import { useTranslation } from '@lobox/utils';

const FollowingYouIcon: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Flex flexDir="row">
      <Icon size={12} name="check-circle" color="success" />
      <Typography font="700" height={12} size={11} color="success" ml={4}>
        {t('following_you')}
      </Typography>
    </Flex>
  );
};

export default FollowingYouIcon;
