import { isClient } from '@lobox/utils';
import { useEffect, useRef, useState } from 'react';
import useMedia from '@lobox/uikit/utils/useMedia';

interface ScrollDir {
  dir: 'up' | 'down';
  lastTop: number;
  applyDirNextTime: boolean;
}

type UseScrollDirProps = {
  scrollRef: any;
  headerRef: any;
};

const useScrollDir = ({ scrollRef, headerRef }: UseScrollDirProps) => {
  const { isMoreThanTablet } = useMedia();
  const [shrink, setShrink] = useState<boolean>(false);

  const scrollDirRef = useRef<ScrollDir>({
    dir: 'down',
    lastTop: 0,
    applyDirNextTime: false,
  });
  const ratioShrinkPossibility = (): boolean => {
    if (isClient()) {
      return (
        (scrollRef?.current?.view?.scrollHeight || 0) / window.innerHeight > 1.3
      );
    }
    return false;
  };

  const getRecs = () => ({
    scrollRec: scrollRef?.current?.view?.getBoundingClientRect(),
    headerRec: headerRef?.current?.getBoundingClientRect(),
  });

  const setScrollInfo = () => {
    const { headerRec } = getRecs();

    if (
      headerRec?.top > scrollDirRef.current.lastTop &&
      scrollDirRef.current.dir === 'down'
    ) {
      if (scrollDirRef.current.applyDirNextTime) {
        scrollDirRef.current.dir = 'up';
      } else {
        scrollDirRef.current.applyDirNextTime = true;
      }
      scrollDirRef.current.lastTop = headerRec?.top;
    } else if (headerRec?.top < scrollDirRef.current.lastTop) {
      scrollDirRef.current.dir = 'down';
      scrollDirRef.current.lastTop = headerRec?.top;
      scrollDirRef.current.applyDirNextTime = false;
    }

    if (
      headerRec?.top < scrollDirRef.current.lastTop &&
      scrollDirRef.current.dir === 'up'
    ) {
      if (scrollDirRef.current.applyDirNextTime) {
        scrollDirRef.current.dir = 'down';
      } else {
        scrollDirRef.current.applyDirNextTime = true;
      }
      scrollDirRef.current.lastTop = headerRec?.top;
    } else if (headerRec?.top > scrollDirRef.current.lastTop) {
      scrollDirRef.current.dir = 'up';
      scrollDirRef.current.lastTop = headerRec?.top;
      scrollDirRef.current.applyDirNextTime = false;
    }
  };

  const getProfileInfoTop = () => {
    const { scrollRec } = getRecs();
    return scrollDirRef.current.lastTop - scrollRec?.top;
  };

  const handleShrinkHeader = () => {
    const profileInfoTop = getProfileInfoTop();

    if (
      !ratioShrinkPossibility() ||
      !scrollRef?.current ||
      !headerRef?.current ||
      (scrollDirRef.current.applyDirNextTime && profileInfoTop > 0)
    ) {
      return;
    }

    if (profileInfoTop < 0) {
      setShrink(true);
    }
  };

  const handleExpandHeader = () => {
    const { scrollRec } = getRecs();
    const profileInfoTop = getProfileInfoTop();

    if (
      !scrollRef?.current ||
      !headerRef?.current ||
      (scrollDirRef.current.applyDirNextTime && profileInfoTop < scrollRec.top)
    ) {
      return;
    }

    if (profileInfoTop > scrollRec.top && shrink) {
      setShrink(false);
    }
  };

  const handleScroll = () => {
    setScrollInfo();
    handleExpandHeader();
    handleShrinkHeader();
  };

  useEffect(() => {
    handleShrinkHeader();
    handleExpandHeader();
  }, [isMoreThanTablet]); // eslint-disable-line react-hooks/exhaustive-deps

  return { shrink, handleScroll };
};

export default useScrollDir;
