import React from 'react';
import ShowGalleryLightBox from '@shared/components/Organism/ShowGalleryLightBox/ShowGalleryLightBox';

interface ViewImageProps {
  image?: string;
  onClose: (...args: any[]) => any;
  title?: string;
}

const ViewImag: React.FC<ViewImageProps> = ({ image, onClose }) => (
  <ShowGalleryLightBox
    onClose={onClose}
    media={[{ type: 'image', path: image, id: '1' }]}
  />
);

export default ViewImag;
