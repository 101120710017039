import classes from './ProfileHeader.EditButton.module.scss';

import React, { useState } from 'react';
import ErrorBoundaryToast from '@lobox/uikit/ErrorBoundary/ErrorBoundaryToast';
import Flex from '@lobox/uikit/Flex';
import IconButton from '@lobox/uikit/Button/IconButton';
import PopperMenu from '@lobox/uikit/PopperMenu';
import useMedia from '@lobox/uikit/utils/useMedia';
import cnj from '@lobox/uikit/utils/cnj';
import { useTranslation } from '@lobox/utils';
import { useProfilePage } from '@shared/hooks';
import {
  ModalBody,
  ModalDialog,
  ModalHeaderSimple,
} from '@shared/components/molecules';
import InvitePeopleModal from '@shared/components/Organism/InvitePeopleModal';
import AssignPageRole from '@shared/components/Organism/AssignPageRole';
import ProfileHeaderActions from './ProfileHeader.actions';
import ConfirmPasswordAndResetModal from '@shared/components/Organism/ConfirmAndResetPasswordModal';
import { isBusinessApp } from '@shared/utils/getAppEnv';
import dynamic from 'next/dynamic';

const MemberSince = dynamic(() => import('./MemberSince'));

interface HeaderEditButtonProps {
  basename?: string;
  isPageCreation: boolean;
  isAuthUser: boolean;
  isPrivateAccount: boolean;
}

const HeaderEditButton: React.FC<HeaderEditButtonProps> = ({
  basename,
  isPageCreation,
  isPrivateAccount,
}) => {
  const { t } = useTranslation();
  const [isOpenPageRolesModal, setIsOpenPageRolesModal] = useState(false);
  const [isOpenInvitePeople, setIsOpenInvitePeople] = useState(false);
  const [visibleConfirmModal, setVisibleConfirmModal] =
    useState<boolean>(false);
  const [visiblePageRoleModal, setVisiblePageRoleModal] = useState(true);
  const [dirtyPageRole, setDirtyPageRole] = useState(false);
  const { isAuthUser, objectDetail } = useProfilePage();
  const { isMoreThanTablet } = useMedia();
  const closePageRolesModal = () => setIsOpenPageRolesModal(false);
  const closeInvitePeople = () => setIsOpenInvitePeople(false);
  const openInvitePeople = () => setIsOpenInvitePeople(false);

  const roleModalClassName = visiblePageRoleModal
    ? classes.visible
    : classes.hide;

  return (
    <Flex className={classes.moreButtonWrapper}>
      {!isBusinessApp && <MemberSince className={classes.memberSince} />}
      <PopperMenu
        placement="bottom-end"
        closeOnScroll
        buttonComponent={
          <IconButton
            type="fas"
            name="ellipsis-h"
            size="md"
            className={cnj(
              classes.moreButton,
              isPageCreation && classes.isPageCreation
            )}
          />
        }
      >
        <ProfileHeaderActions
          openInvitePeople={openInvitePeople}
          setIsOpenPageRolesModal={setIsOpenPageRolesModal}
          basename={basename}
          isPageCreation={isPageCreation}
          isAuthUser={isAuthUser}
          isPrivateAccount={isPrivateAccount}
          setVisibleConfirmModal={setVisibleConfirmModal}
        />
      </PopperMenu>
      {isBusinessApp && (
        <ErrorBoundaryToast>
          <>
            <ModalDialog
              isOpen={isOpenPageRolesModal}
              onBack={closePageRolesModal}
              onClose={closePageRolesModal}
              modalClassName={roleModalClassName}
              backdropClassName={roleModalClassName}
              contentClassName={roleModalClassName}
              modalDialogClassName={roleModalClassName}
              showConfirm={dirtyPageRole}
            >
              <ModalHeaderSimple
                title={t('page_roles')}
                hideBack={isMoreThanTablet}
              />
              <ModalBody className={classes.modalBody}>
                <AssignPageRole
                  setPageRoleModalVisibility={setVisiblePageRoleModal}
                  setDirtyStatus={setDirtyPageRole}
                />
              </ModalBody>
            </ModalDialog>
          </>
        </ErrorBoundaryToast>
      )}
      {isOpenInvitePeople && (
        <InvitePeopleModal
          isOpen={isOpenInvitePeople}
          onClose={closeInvitePeople}
          onBack={closeInvitePeople}
          onSuccess={console.log}
        />
      )}
      <ConfirmPasswordAndResetModal
        visibleConfirmModal={visibleConfirmModal}
        setVisibleConfirmModal={setVisibleConfirmModal}
        id={objectDetail?.id}
      />
    </Flex>
  );
};

export default HeaderEditButton;
