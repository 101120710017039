'use client';

import React, { useRef } from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import FooterDesktop from '@lobox/uikit/Footer/FooterDesktop';
import Media from '@lobox/uikit/Media';
import Tabs from '@lobox/uikit/Tabs';
import ScrollView from '@lobox/uikit/ScrollView';
import { useLocation, useParams } from '@lobox/utils';
import {
  routeNames,
  ScrollViewElement,
  useAuthState,
  useTranslation,
  useGlobalState,
  useGlobalDispatch,
} from '@lobox/utils';
import urls from '@shared/constants/urls';
import {
  ModalHeaderSimple,
  PermissionsGate,
} from '@shared/components/molecules';
import { pageStatus, PROFILE_SCROLL_WRAPPER } from '@shared/constants/enums';
import { useProfilePage } from '@shared/hooks';
import { SCOPES } from '@shared/constants/userRoles.scopes';
import useHasPermission from '@shared/hooks/useHasPermission';
import dynamic from 'next/dynamic';
import type { QueryParamsType } from '@lobox/utils';
import ProfileHeader from '../ProfileLayout.Header';
import useScrollDir from '@shared/hooks/useScrollDir';
import ResponsiveGlobalSearch from '@shared/components/molecules/ResponsiveGlobalSearch';
import ProfileLayoutActions from './ProfileLayout.ActionButtons';
import useScrollToTopEventListener from '@shared/utils/useScrollToTopEventListener';
import classes from './ProfileLayout.Content.module.scss';
import ObjectFormContainer from '@shared/components/Organism/ObjectFormContainer';

const ProfileHeaderPublishPage = dynamic(
  () => import('../ProfileLayout.Header/partials/ProfileHeader.PublishPage'),
  { ssr: false }
);
const BlockedAccount = dynamic(
  () => import('@shared/components/molecules/BlockedAccount/BlockedAccount'),
  { ssr: false }
);

interface ProfileLayoutContentProps {
  children?: ReactNode;
}

const ProfileLayoutContent: React.FC<ProfileLayoutContentProps> = ({
  children,
}) => {
  const globalDispatch = useGlobalDispatch();
  const isLoggedIn = useAuthState('isLoggedIn');
  const canSeeDashboardTab = useHasPermission([SCOPES.canSeeDashboardTab]);
  const canSeeAboutTab = useHasPermission([SCOPES.canSeeAboutTab]);
  const canSeeFeedTab = useHasPermission([SCOPES.canSeeFeedTab]);
  const canSeePagesTab = useHasPermission([SCOPES.canSeePagesTab]);
  const canSeeCollectionsTab = useHasPermission([SCOPES.canSeeCollectionsTab]);
  const canSeeHashtagTab = useHasPermission([SCOPES.canSeeHashtagTab]);
  const location = useLocation();
  const { pathname } = location;
  const { username } = useParams<QueryParamsType>();
  const { t } = useTranslation();
  const scrollRef = useRef<ScrollViewElement>(null);
  const headerRef = useRef<HTMLElement>(null);
  const { shrink, handleScroll } = useScrollDir({ headerRef, scrollRef });
  const profileHistoryCount = useGlobalState('profileHistoryCount');
  useScrollToTopEventListener();

  const {
    isBusinessApp,
    isAuthUser,
    isPageCreation,
    objectDetail,
    isPage,
    isAuthBusinessPage,
    isPrivateAccount,
  } = useProfilePage();

  const blockedAccount =
    objectDetail?.youHaveBlocked || objectDetail?.youAreBlocked;

  const visibleFooter =
    (pathname !== `/${username}${routeNames.feed}` ||
      pathname !== routeNames.pageCreation) &&
    !blockedAccount &&
    !isPrivateAccount;
  const showPublishPageButton =
    isPageCreation || objectDetail?.status === pageStatus.UNPUBLISHED;
  const visibleAboutTab = canSeeAboutTab;
  const isVisibleHashtagTab =
    isLoggedIn &&
    !isPrivateAccount &&
    canSeeHashtagTab &&
    (isAuthUser ||
      isAuthBusinessPage ||
      isPageCreation ||
      objectDetail?.network?.hasAnyHashtagFollowings);
  const visibleFeedTab = isLoggedIn && canSeeFeedTab && !isPrivateAccount;
  const isVisiblePagesTab =
    isLoggedIn &&
    !isPrivateAccount &&
    canSeePagesTab &&
    (isAuthUser ||
      isAuthBusinessPage ||
      isPageCreation ||
      objectDetail?.network?.hasAnyPageFollowings);

  const isVisibleJobsTab =
    isLoggedIn && !isPrivateAccount && (isAuthUser || isPageCreation);
  const isVisibleSchedulesTab =
    isLoggedIn && !isPrivateAccount && (isAuthUser || isPageCreation);

  const visibleDashboardTab =
    isLoggedIn &&
    !isPrivateAccount &&
    canSeeDashboardTab &&
    (isAuthUser || isPageCreation || (isAuthBusinessPage && isBusinessApp));

  const isVisibleCollectionsTab =
    isLoggedIn &&
    !isPrivateAccount &&
    canSeeCollectionsTab &&
    (isAuthUser || isAuthBusinessPage || objectDetail?.hasAnyPublicCollection);

  const tabs = blockedAccount
    ? []
    : [
        visibleAboutTab && {
          path: isPageCreation
            ? `${routeNames.page}${routeNames.pageCreation}`
            : `/${username}`,
          title: t('about'),
          exact: true,
        },
        visibleFeedTab && {
          path: `/${username}${routeNames.feed}`,
          title: t('feed'),
          disabled: isPageCreation,
        },
        isVisiblePagesTab && {
          path: `/${username}${routeNames.profilePages}`,
          title: t('pages'),
          disabled: isPageCreation,
        },
        isVisibleJobsTab && {
          path: `/${username}${routeNames.jobs}`,
          title: t('jobs'),
          disabled: isPageCreation,
        },
        isVisibleHashtagTab && {
          path: `/${username}${routeNames.hashtags}`,
          title: t('hashtags'),
          disabled: isPageCreation,
        },
        isVisibleCollectionsTab && {
          path: `/${username}${routeNames.collections}`,
          title: t('collections'),
          disabled: isPageCreation,
        },
        isVisibleSchedulesTab && {
          path: `/${username}${routeNames.schedules}`,
          title: t('schedules'),
          disabled: isPageCreation,
        },
        visibleDashboardTab && {
          path: `/${username}${routeNames.dashboard}`,
          title: t('insights'),
          disabled: isPageCreation,
        },
      ].filter(Boolean);

  const onBackHandler = () => {
    if (profileHistoryCount > 0) {
      window.history.go(profileHistoryCount * -1);
      globalDispatch({ type: 'RESET_PROFILE_HISTORY_COUNT' });
      return;
    }
    window.history.back();
  };

  const onTabChangeHandler = () => {
    if (shrink && scrollRef?.current?.view) {
      setTimeout(() => {
        scrollRef?.current?.view?.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }, 150);
    }
  };

  return (
    <>
      <ModalHeaderSimple
        visibleHeaderDivider
        className={classes.mobileHeader}
        title={isPage ? t('page_details') : t('user_details')}
        rightContent={() => <ResponsiveGlobalSearch />}
        backButtonProps={{ onClick: onBackHandler }}
      />
      <ScrollView
        id={PROFILE_SCROLL_WRAPPER}
        autoHide
        className={classes.profileRoot}
        onScroll={handleScroll}
        ref={scrollRef}
        style={{ width: 'auto' }}
        renderTrackVertical={(props: any) => (
          <Flex {...props} className={classes.trackVertical} />
        )}
      >
        {showPublishPageButton && <ProfileHeaderPublishPage />}
        <ProfileHeader
          headerRef={headerRef}
          isShrink={shrink}
          actionButtons={
            <ProfileLayoutActions
              blockedAccount={blockedAccount as boolean}
              className={cnj(isPrivateAccount && classes.paddingBottom8)}
            />
          }
          showPublishPageButton={showPublishPageButton}
          blockedAccount={blockedAccount as boolean}
        />
        <Tabs
          onTabChange={onTabChangeHandler}
          moreLabel={t('more')}
          isFullWidth={false}
          linksRootClassName={cnj(
            shrink && classes.linksRootShrink,
            showPublishPageButton && classes.linksRootUnpublished
          )}
          contentRootClassName={cnj(
            classes.contentRootClassName,
            shrink && classes.contentRootShrink,
            blockedAccount && classes.fullHeight,
            (isPageCreation || isPage) &&
              shrink &&
              classes.pageCreationContentRootShrink
          )}
          className={cnj(
            classes.tabsRootWrapper,
            blockedAccount && classes.flexOne
          )}
          innerClassName={cnj(isPageCreation && classes.disabledContentClick)}
          navLinkClassName={classes.navLinkClassName}
          linkAndActionWrapperClassName={cnj(
            classes.linkAndActionWrapperClassName,
            classes.borderTop
          )}
          tabs={tabs}
          tabsShowLimit={7}
          actionButton={
            <PermissionsGate scopes={[SCOPES.canSeeProfileHeaderRightActions]}>
              <Media greaterThan="tablet">
                <ProfileLayoutActions blockedAccount={blockedAccount as any} />
              </Media>
            </PermissionsGate>
          }
        >
          {objectDetail?.youHaveBlocked ? (
            <BlockedAccount isPage={isPage} />
          ) : (
            <>{children}</>
          )}
        </Tabs>
        {visibleFooter && (
          <Media greaterThan="tablet">
            <FooterDesktop baseUrl={urls.base} logoGray />
          </Media>
        )}

        <ObjectFormContainer scrollRef={scrollRef} />
      </ScrollView>
    </>
  );
};

export default ProfileLayoutContent;
