import React from 'react';
import Flex from '@lobox/uikit/Flex';
import PopperItem from '@lobox/uikit/PopperItem';
import Typography from '@lobox/uikit/Typography';
import useOpenConfirm from '@lobox/uikit/Confirmation/useOpenConfirm';
import useShare from '@lobox/uikit/utils/useShare';
import {
  PAGE_ROLES,
  useGlobalDispatch,
  useTranslation,
  translateReplacer,
} from '@lobox/utils';
import openBusinessApp from '@shared/utils/openBusiness';
import { useProfileModals, useProfilePage } from '@shared/hooks';
import {
  ASK_RECOMMENDATION,
  CREATE_PAGE_FORM,
  EDIT_PROFILE,
} from '@shared/constants/profileModalsKeys';
import useObjectNetwork from '@shared/hooks/api-hook/useObjectNetwork';
import useBlock from '@shared/hooks/api-hook/useBlock';
import useGetAppObject from '@shared/hooks/useGetAppObject';
import useGetRole from '@shared/hooks/useGetRole';
import { useParams } from '@lobox/utils';
import useShareEntity from '@shared/hooks/useShareEntity';
import type { UserType, QueryParamsType, PageType } from '@lobox/utils';
import { isBusinessApp } from '@shared/utils/getAppEnv';

interface HeaderEditButtonProps {
  basename?: string;
  isPageCreation: boolean;
  isAuthUser: boolean;
  isPrivateAccount: boolean;
  setIsOpenPageRolesModal: any;
  openInvitePeople: any;
  setVisibleConfirmModal: any;
}

const ProfileHeaderActions: React.FC<HeaderEditButtonProps> = ({
  basename,
  isPrivateAccount,
  setIsOpenPageRolesModal,
  openInvitePeople,
  setVisibleConfirmModal,
}) => {
  const { t } = useTranslation();
  const { isShareable, shareContact } = useShare();
  const { onClick: onShare } = useShareEntity();
  const { open } = useProfileModals();
  const { openConfirmDialog } = useOpenConfirm();
  const {
    getObjectProp,
    objectDetail,
    network,
    isPage,
    hasMembership,
    reFetchPageDetail,
    isAuthBusinessPage,
    updateObjectDetailQueryCache,
    isAuthUser,
  } = useProfilePage();
  const { roles } = useGetRole();
  const { checkIsBusinessOwner, authUser } = useGetAppObject();
  const { username: paramUsername } = useParams<QueryParamsType>();
  const canEditProfile =
    roles.includes(PAGE_ROLES.ADMIN.value) ||
    roles.includes(PAGE_ROLES.EDITOR.value);
  const appDispatch = useGlobalDispatch();
  const { unfollowHandler: unfollow } = useObjectNetwork();
  const { block: blockHandler } = useBlock();
  const title = getObjectProp({ userKey: 'fullName', pageKey: 'title' });
  const unPublishedPage = objectDetail?.status === 'UNPUBLISHED';

  const following = network?.follow;

  const openRecommend = () => {
    open(ASK_RECOMMENDATION, {
      receiverId: objectDetail?.id,
      isWriteRecommend: true,
      recommender: {
        ...objectDetail,
        occupationName: objectDetail?.occupation?.label,
      },
    });
  };
  const handleShare = () => {
    const { hostname } = window.location;
    shareContact(`${hostname}/${basename}`);
  };
  const openEditProfile = () => open(EDIT_PROFILE);
  const openEdiPage = () => open(CREATE_PAGE_FORM, objectDetail);
  const openPageRoles = () => setIsOpenPageRolesModal(true);
  const onBlockHandler = () =>
    blockHandler(
      {
        entityId: objectDetail?.id,
        entityType: isPage ? 'PAGE' : 'PERSON',
      },
      {
        onSuccess: () => {
          updateObjectDetailQueryCache(
            (data: UserType): UserType => ({
              ...data,
              youHaveBlocked: true,
            })
          );
        },
      }
    );

  const openBlockConfirmation = () =>
    openConfirmDialog({
      title: (
        <Typography isTruncated isWordWrap>
          <div
            dangerouslySetInnerHTML={{
              __html: translateReplacer(t('block_name'), title),
            }}
          />
        </Typography>
      ),
      message: <Typography mt={4}>{t('blocking_reasons')}</Typography>,
      confirmButtonText: t('block'),
      cancelButtonText: t('cancel'),
      confirmCallback: onBlockHandler,
    });

  const toggleReportModal = () => {
    appDispatch({
      type: 'TOGGLE_REPORT_MODAL',
      payload: {
        isOpen: true,
        data: {
          entityType: isPage ? 'page' : 'user',
          entityId: objectDetail?.id,
        },
      },
    });
  };

  const deleteAccountHandler = () => {
    openConfirmDialog({
      title: t('delete_y_page'),
      message: (
        <Flex>
          <Typography>{t('r_y_s_d_page')}</Typography>
        </Flex>
      ),
      confirmButtonText: t('delete_page'),
      cancelButtonText: t('cancel'),
      confirmCallback: () => setVisibleConfirmModal(true),
    });
  };

  const INVITE_ACTION = {
    key: 'invite',
    icon: 'user-plus',
    label: t('invite_people'),
    onClick: openInvitePeople,
  };

  const SHARE_PROFILE = {
    key: 'share_profile',
    icon: 'share',
    label: t(isPage ? 'share_page' : 'share_profile'),
    onClick: () => {
      if (isPage) {
        onShare({
          type: 'page',
          tabs: [
            'copy_link',
            'share_via_post',
            'share_via_message',
            'share_via_email',
          ],
          entityData: objectDetail as PageType,
        });
      } else {
        onShare({
          type: 'profile',
          tabs: [
            'copy_link',
            'share_via_post',
            'share_via_message',
            'share_via_email',
          ],
          entityData: objectDetail as UserType,
        });
      }
    },
  };

  const SHAREVIA_MESSAGE_ACTION = !isPrivateAccount ? SHARE_PROFILE : undefined;

  const SHAREVIA_ACTION = {
    key: 'share_via',
    icon: 'share-alt',
    label: t('share_via_3dot'),
    onClick: handleShare,
  };
  // const follow = {
  //   key: 'follow',
  //   icon: 'plus',
  //   label: t('follow'),
  // };

  const BLOCK_ACTION = {
    key: 'block',
    icon: 'ban',
    label: t('block'),
    onClick: openBlockConfirmation,
  };
  const REPORT_ACTION = {
    key: 'report',
    icon: 'pennant',
    label: isPage ? t('report_page') : t('report_user'),
    onClick: toggleReportModal,
  };
  const EDIT_PAGE_ACTION = {
    key: 'pen',
    icon: 'pen',
    label: t('edit_page_details'),
    onClick: openEdiPage,
  };
  const EDIT_PROFILE_ACTION = {
    key: 'pen',
    icon: 'pen',
    label: t('edit_profile'),
    onClick: openEditProfile,
  };
  const PAGE_ROLES_ACTION = {
    key: 'PAGE_ROLES_ACTION',
    icon: 'user-cog',
    label: t('page_roles'),
    onClick: openPageRoles,
  };
  // const unFollow = {
  //   key: 'unfollow',
  //   icon: 'times-square',
  //   label: t('unfollow'),
  // };
  const USE_LOBOX_AS_ACTION = {
    key: 'use_lobox_ax',
    icon: 'sign-in-alt',
    label: translateReplacer(t('use_lobox_as_name'), objectDetail?.title),
    onClick: () => openBusinessApp(objectDetail?.username),
  };

  const RECOMMEND_ACTION = {
    key: 'comment-alt-edit',
    icon: 'comment-alt-edit',
    label: t('recommendation'),
    onClick: openRecommend,
  };

  const DELETE_ACTION = {
    key: 'comment-alt-edit',
    label: t('delete_y_page'),
    onClick: deleteAccountHandler,
  };

  const adminActions = [
    INVITE_ACTION,
    SHAREVIA_MESSAGE_ACTION,
    isShareable && SHAREVIA_ACTION,
    isAuthBusinessPage && EDIT_PAGE_ACTION,
    isAuthBusinessPage && PAGE_ROLES_ACTION,
  ];

  const editorActions = [
    INVITE_ACTION,
    SHAREVIA_MESSAGE_ACTION,
    isShareable && SHAREVIA_ACTION,
    isAuthBusinessPage && EDIT_PAGE_ACTION,
    isAuthBusinessPage && PAGE_ROLES_ACTION,
  ];

  const hrAndExpertActions = [
    INVITE_ACTION,
    SHAREVIA_MESSAGE_ACTION,
    isShareable && SHAREVIA_ACTION,
  ];

  const nonFollowingActions = [
    SHAREVIA_MESSAGE_ACTION,
    isShareable && SHAREVIA_ACTION,
    INVITE_ACTION,
    // follow,
    BLOCK_ACTION,
    REPORT_ACTION,
  ];

  const myProfileInBusinsssAction = [
    SHARE_PROFILE,
    isShareable && SHAREVIA_ACTION,
    EDIT_PROFILE_ACTION,
  ];

  const followerActions = [
    SHAREVIA_MESSAGE_ACTION,
    isShareable && SHAREVIA_ACTION,
    INVITE_ACTION,
    // unFollow,
    BLOCK_ACTION,
    REPORT_ACTION,
  ];

  const myPageNonSwitchedActions = [
    canEditProfile && EDIT_PAGE_ACTION,
    SHAREVIA_MESSAGE_ACTION,
    isShareable && SHAREVIA_ACTION,
    INVITE_ACTION,
    USE_LOBOX_AS_ACTION,
  ];

  const isSafeMode = (() =>
    (!isAuthUser && !isBusinessApp) ||
    (isBusinessApp &&
      !checkIsBusinessOwner(objectDetail?.id) &&
      authUser?.id !== objectDetail?.id))();

  const userActions = [
    SHAREVIA_MESSAGE_ACTION,
    isShareable && SHAREVIA_ACTION,
    isAuthUser && EDIT_PROFILE_ACTION,
    !isAuthUser && !isBusinessApp && !isPrivateAccount && RECOMMEND_ACTION,
    isSafeMode && BLOCK_ACTION,
    isSafeMode && REPORT_ACTION,
  ];

  const unPublishedActions = [DELETE_ACTION];

  const actions = (() => {
    if (!isPage) {
      if (
        isBusinessApp &&
        !canEditProfile &&
        authUser?.username === paramUsername
      ) {
        return myProfileInBusinsssAction;
      }
      return userActions;
    }
    if (unPublishedPage && hasMembership) {
      return unPublishedActions;
    }
    if (isBusinessApp && roles.includes(PAGE_ROLES.ADMIN.value)) {
      return adminActions;
    }
    if (isBusinessApp && roles.includes(PAGE_ROLES.EDITOR.value)) {
      return editorActions;
    }
    if (
      isBusinessApp &&
      (roles.includes(PAGE_ROLES.RECRUITER.value) ||
        roles.includes(PAGE_ROLES.EXPERT.value))
    ) {
      return hrAndExpertActions;
    }
    if (!isBusinessApp && hasMembership) {
      return myPageNonSwitchedActions;
    }
    if (following) {
      return followerActions;
    }
    return nonFollowingActions;
  })();

  return (
    <>
      {actions
        ?.filter(Boolean)
        .map(({ icon, label, key, onClick }: any) => (
          <PopperItem
            onClick={onClick}
            iconName={icon}
            label={label}
            key={key}
            labelTruncated
            secondLabelTruncated
          />
        ))}
    </>
  );
};

export default React.memo(ProfileHeaderActions);
