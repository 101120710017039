// @ts-nocheck

import { Endpoints, request } from '@lobox/utils';

export const setUserAvatar = (data) =>
  request.put(Endpoints.Auth.setUserAvatar, data);

export const setPageAvatar = (data) =>
  request.put(Endpoints.App.Page.setAvatar(data.pageId), data);

export const setHeaderBackground = (data) =>
  request.put(Endpoints.App.User.Update.cover, data);

export const setPageHeaderBackground = (data) =>
  request.put(Endpoints.App.Page.setHeaderBackground(data.pageId), data);
