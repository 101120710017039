import classes from './InvitePeople.modal.module.scss';

import React from 'react';
import SearchInput from '@lobox/uikit/SearchInput';
import Flex from '@lobox/uikit/Flex';
import CheckBox from '@lobox/uikit/CheckBox';
import FieldWrapper from '@lobox/uikit/Form/FieldWrapper';
import Form from '@lobox/uikit/Form';
import SubmitButton from '@lobox/uikit/Form/SubmitButton';
import BaseButton from '@lobox/uikit/Button/BaseButton';
import Typography from '@lobox/uikit/Typography';
import useToast from '@lobox/uikit/Toast/useToast';
import { useTranslation } from '@lobox/utils';
import xor from 'lodash/xor';
import {
  ModalBody,
  ModalDialog,
  ModalFooter,
  UserItemWithAction,
  ModalHeaderWithAction,
} from '../../molecules';

const peoples = [
  {
    id: '1',
    title: 'Zack Cooper',
    subTitle: '@JCooper',
    image: 'https://i.pravatar.cc/140?img=11',
  },
  {
    id: '2',
    title: 'Aasdf Cooper',
    subTitle: '@JCooper',
    image: 'https://i.pravatar.cc/140?img=11',
  },
  {
    id: '3',
    title: 'Badf Cooper',
    subTitle: '@JCooper',
    image: 'https://i.pravatar.cc/140?img=11',
  },
  {
    id: '4',
    title: 'Cad Cooper',
    subTitle: '@JCooper',
    image: 'https://i.pravatar.cc/140?img=11',
  },
  {
    id: '5',
    title: 'Val Cooper',
    subTitle: '@JCooper',
    image: 'https://i.pravatar.cc/140?img=11',
  },
  {
    id: '6',
    title: 'Zack3 Cooper',
    subTitle: '@JCooper',
    image: 'https://i.pravatar.cc/140?img=11',
  },
  {
    id: '7',
    title: 'Zack4 Cooper',
    subTitle: '@JCooper',
    image: 'https://i.pravatar.cc/140?img=11',
  },
  {
    id: '8',
    title: 'Zack5 Cooper',
    subTitle: '@JCooper',
    image: 'https://i.pravatar.cc/140?img=11',
  },
];

export interface InvitePeopleModalProps {
  isOpen: boolean;
  onClose: () => void;
  onBack: () => void;
  onSuccess: (args: any) => void;
}

const InvitePeopleModal: React.FC<InvitePeopleModalProps> = ({
  isOpen,
  onClose,
  onBack,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const toast = useToast();

  const onSuccessHandler = (value: any) => {
    toast({
      type: 'success',
      icon: 'check-circle',
      message: `${t('y_invite')} ${value?.users?.lenth} ${t(
        'people_to_lobox'
      )}`,
    });
    onSuccess(value);
  };

  return (
    <Form local initialValues={{ users: [] }} onSuccess={onSuccessHandler}>
      {({ dirty, setFieldValue }: any) => {
        const selectHandler = () =>
          setFieldValue(
            'users',
            peoples.map((i) => i.id)
          );
        return (
          <ModalDialog
            isOpen={isOpen}
            onClose={onClose}
            onBack={onBack}
            variant="simple"
          >
            <ModalHeaderWithAction
              title={t('invite_people')}
              renderAction={
                <BaseButton onClick={selectHandler}>
                  <Typography color="brand" ml={8}>{`${t('select_all')} ${
                    peoples.length
                  }`}</Typography>
                </BaseButton>
              }
            />
            <ModalBody>
              <SearchInput />
              <Flex className={classes.listWrapper}>
                {peoples.map(({ id, title, subTitle, image }) => (
                  <FieldWrapper
                    name="users"
                    component={({ value, onChange }: any) => {
                      const onChangeHandler = () => onChange(xor(value, [id]));
                      const isSelected = value.includes(id);

                      return (
                        <UserItemWithAction
                          key={id}
                          containerProps={{
                            onClick: onChangeHandler,
                          }}
                          data={{ image, title, subTitle }}
                          subTitleProps={{
                            color: 'primaryText',
                            isTruncated: true,
                          }}
                          action={
                            <CheckBox
                              value={isSelected}
                              onChange={onChangeHandler}
                            />
                          }
                        />
                      );
                    }}
                  />
                ))}
              </Flex>
            </ModalBody>
            <ModalFooter>
              <SubmitButton
                disabled={dirty}
                label={t('invite')}
                className={classes.inviteBnt}
              />
            </ModalFooter>
          </ModalDialog>
        );
      }}
    </Form>
  );
};

export default InvitePeopleModal;
