import classes from './ProfileHeader.Avatar.module.scss';

import React from 'react';
import Avatar from '@lobox/uikit/Avatar';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import Media from '@lobox/uikit/Media';
import {
  Endpoints,
  fileApi,
  useGlobalDispatch,
  useReactMutation,
  useTranslation,
} from '@lobox/utils';
import { useProfile, useProfilePage } from '@shared/hooks';
import useHasPermission from '@shared/hooks/useHasPermission';
import { SCOPES } from '@shared/constants/userRoles.scopes';
import { setPageAvatar, setUserAvatar } from './header.utils';
import HeaderImageActions from './ProfileHeader.ImageActions';

interface HeaderAvatarProps {
  isCompany?: boolean;
  imgSrc?: string;
  originalImg?: string;
  croppedImageData: {
    rotate?: number;
    zoom?: number;
    position?: { x: number; y: number };
  };
  disabled: boolean;
}

const ProfileHeaderAvatar: React.FC<HeaderAvatarProps> = ({
  isCompany,
  imgSrc,
  croppedImageData,
  originalImg,
  disabled,
}) => {
  const { t } = useTranslation();
  const { reFetchAuthUserQuery, reFetchBusinessPageQuery } = useProfile();
  const dispatch = useGlobalDispatch();

  const onClickAvatarHandler = () => {
    if (originalImg) {
      dispatch({
        type: 'OPEN_VIEW_MEDIA',
        payload: { medias: [{ type: 'image', path: imgSrc, id: 1 }] },
      });
    }
  };

  const {
    isPage,
    isAuthUser,
    isAuthBusinessPage,
    objectDetail,
    reFetchPageDetail,
  } = useProfilePage();
  const pageId = objectDetail?.id;
  const hasEditAccess = useHasPermission([SCOPES.canEditProfile]);
  const visibleAvatarAction =
    isAuthUser || (isAuthBusinessPage && hasEditAccess);
  const { mutate: setUserAvatarHandler } = useReactMutation({
    apiFunc: setUserAvatar,
  });
  const { mutate: setPageAvatarHandler } = useReactMutation({
    apiFunc: setPageAvatar,
  });

  const onSuccess = (
    { originalResponse, croppedResponse }: any,
    variables: any
  ) => {
    const imageData = {
      imageUrl: originalResponse?.data?.link,
      croppedImageUrl: croppedResponse?.data?.link,
      croppedImageData: JSON.stringify(variables?.image?.croppedData || {}),
    };

    if (isPage) {
      setPageAvatarHandler(
        { pageId, ...imageData },
        {
          onSuccess: () => {
            reFetchPageDetail();
            reFetchBusinessPageQuery();
          },
        }
      );
    } else {
      setUserAvatarHandler(imageData, {
        onSuccess: () => {
          reFetchPageDetail();
          reFetchAuthUserQuery();
        },
      });
    }
  };

  const onSuccessDelete = () => {
    if (isAuthBusinessPage) {
      reFetchBusinessPageQuery();
    }
    reFetchPageDetail();
    reFetchAuthUserQuery();
  };

  return (
    <>
      <Flex className={classes.headerAvatarRoot}>
        <Media at="tablet">
          <Avatar
            onClick={!disabled ? onClickAvatarHandler : undefined}
            className={classes.avatar}
            imgSrc={imgSrc}
            isCompany={isCompany}
            size="vlg"
            isOnline={isAuthUser}
            resolution="small"
          />
        </Media>
        <Media greaterThan="tablet">
          <Avatar
            onClick={!disabled ? onClickAvatarHandler : undefined}
            imgSrc={imgSrc}
            className={cnj(
              classes.avatar,
              originalImg && !disabled && classes.pointer
            )}
            isCompany={isCompany}
            size="xl"
            resolution="small"
            isOnline={isAuthUser}
          />
        </Media>
        {visibleAvatarAction && (
          <HeaderImageActions
            {...{
              cropShape: isPage ? 'rect' : 'round',
              cropSize: {
                width: 250,
                height: 250,
              },
              type: 'avatar',
              croppedImageData,
              className: cnj(classes.imageIcon, isCompany && classes.isCompany),
              imgSrc,
              originalImg,
              isCompany,
              offsetY: 64,
              placement: 'bottom',
              onSuccess,
              onSuccessDelete,
              url: isPage
                ? Endpoints.App.Page.setAvatar(pageId)
                : Endpoints.Auth.setUserAvatar,
              apiFunc: fileApi.uploadCroppedImage,
              labels: {
                view: t('view_profile_image'),
                edit: t('edit_profile_image'),
                delete: t('delete_profile_image'),
                deleteHint: t('delete_profile_image_hint'),
                save: t('save'),
                upload: t('upload_another'),
                deleteBtn: t('delete'),
                editImage: t('edit_image'),
                viewImage: t('view_image'),
                deleteImage: t('delete_image'),
              },
            }}
          />
        )}
      </Flex>
    </>
  );
};

export default ProfileHeaderAvatar;
