import React from 'react';
import { ErrorBoundaryToast } from '@lobox/uikit/ErrorBoundary';
import dynamic from 'next/dynamic';
import { useProfileModals } from '@shared/hooks';

const ObjectFormBuilder = dynamic(() => import('./ObjectFormBuilder'), {
  ssr: false,
});

export interface Props {
  scrollRef?: React.Ref<any>;
}

const ObjectFormContainer: React.FC<Props> = ({ scrollRef } = {}) => {
  const { modals } = useProfileModals();
  const activeModalName = modals?.active as string;

  if (!activeModalName) return null;

  return (
    <ErrorBoundaryToast>
      <ObjectFormBuilder
        scrollRef={scrollRef}
        active={activeModalName}
        key={activeModalName}
      />
    </ErrorBoundaryToast>
  );
};

export default ObjectFormContainer;
