import { isClient } from '@lobox/utils';

const isShareableFunc = (): boolean => {
  if (isClient()) {
    const { share } = navigator || {};

    return !!share;
  }
  return false;
};

const shareContact = async (text: string): Promise<any> => {
  try {
    if (isClient()) {
      await navigator.share({
        text,
      });
    }
  } catch (e) {
    console.error(e);
  }
};

type ShareContentType = (text: string) => Promise<any>;

const useShare = (): {
  isShareable: boolean;
  shareContact: ShareContentType;
} => {
  const isShareable = isShareableFunc();

  return { isShareable, shareContact };
};

export default useShare;
