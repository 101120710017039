'use client';

import React from 'react';
import ProfileProvider from '@shared/contexts/profile.provider';
import ProfileModalsProvider from '@shared/contexts/profileModals.provider';
import ProfileLayoutContent from './ProfileLayout.Content/ProfileLayout.Content';

export default function ProfileLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <ProfileProvider>
      <ProfileModalsProvider>
        <ProfileLayoutContent>{children}</ProfileLayoutContent>
      </ProfileModalsProvider>
    </ProfileProvider>
  );
}
