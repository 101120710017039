import classes from './UnBlockButton.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import Form from '@lobox/uikit/Form';
import SubmitButton from '@lobox/uikit/Form/SubmitButton';
import { APP_ENTITIES, reportApi, useTranslation } from '@lobox/utils';
import { useProfilePage } from '@shared/hooks';

export interface BlockButtonProps {
  className?: string;
}

const UnBlockButton: React.FC<BlockButtonProps> = ({ className }) => {
  const { t } = useTranslation();
  const { updateObjectDetailQueryCache, objectDetail, isPage } =
    useProfilePage();
  const youHaveBlocked = objectDetail?.youHaveBlocked;
  const entityId = objectDetail?.id;
  const entityType = isPage ? APP_ENTITIES.page : APP_ENTITIES.person;
  const onSuccess = () => {
    updateObjectDetailQueryCache((data) => ({
      ...data,
      youHaveBlocked: false,
    }));
  };
  if (!youHaveBlocked) {
    return null;
  }

  return (
    <Flex className={cnj(classes.tabBarAddSectionBtn, className)}>
      <Form
        apiFunc={reportApi.unblock}
        initialValues={{
          entityId,
          entityType,
        }}
        onSuccess={onSuccess}
      >
        {() => (
          <SubmitButton
            leftIcon="undo"
            active
            label={t('unblock')}
            schema="semi-transparent"
          />
        )}
      </Form>
    </Flex>
  );
};

export default UnBlockButton;
