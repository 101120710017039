import { useParams } from '@lobox/utils';
import { getAboutSectionsData, QueryKeys } from '@lobox/utils';
import type { ProfileType, QueryParamsType } from '@lobox/utils';
import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

export type UseGetAboutSectionsDataType = UseQueryResult<ProfileType, any> & {
  aboutSectionDataKey: Array<string>;
};

const useGetAboutSectionsData = ({
  username: argUsername,
}: {
  username?: string;
} = {}): UseGetAboutSectionsDataType => {
  const { username: paramUsername } = useParams<QueryParamsType>();
  const username = argUsername || paramUsername;
  const aboutSectionDataKey = [QueryKeys.aboutSections, username];

  const queryResult = useQuery<ProfileType>({
    queryKey: aboutSectionDataKey,
    queryFn: () => getAboutSectionsData({ params: { username } }),
  });

  return {
    ...queryResult,
    aboutSectionDataKey,
  };
};

export default useGetAboutSectionsData;
