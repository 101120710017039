import classes from './ProfileHeader.component.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import Media from '@lobox/uikit/Media';
import SEO from '@lobox/uikit/SEO';
import useMedia from '@lobox/uikit/utils/useMedia';
import {
  applyResolutionToImageSrc,
  routeNames,
  useAuthState,
  useIsPageCreation,
  useTranslation,
} from '@lobox/utils';
import { useGetAboutSectionsData } from '@shared/hooks/api-hook';
import { EDIT_PROFILE } from '@shared/constants/profileModalsKeys';
import { useProfileModals, useProfilePage } from '@shared/hooks';
import appEnvironment from '@shared/constants/env';
import useHasPermission from '@shared/hooks/useHasPermission';
import { SCOPES } from '@shared/constants/userRoles.scopes';
import { PermissionsGate } from '@shared/components/molecules';
import { isBusinessApp } from '@shared/utils/getAppEnv';
import { useHistory } from '@lobox/utils';
import HeaderUserInfo from './partials/ProfileHeader.UserInfo';
import ProfileHeaderAvatar from './partials/ProfileHeader.Avatar';
import HeaderEditButton from './partials/ProfileHeader.EditButton';
import HeaderBackground from './partials/ProfileHeader.Background';
import type { NetworkModalObjectDataType } from '@shared/types';

interface ProfileHeaderProps {
  isShrink?: boolean;
  basename?: string;
  headerRef?: React.Ref<any>;
  actionButtons?: React.ReactNode;
  showPublishPageButton?: boolean;
  blockedAccount: boolean;
  isPrivateAccount: boolean;
}

const ProfileHeader = ({
  isShrink,
  headerRef,
  basename,
  actionButtons,
  showPublishPageButton,
  blockedAccount,
  isPrivateAccount,
}: ProfileHeaderProps): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const isPageCreation = useIsPageCreation();
  const canSeeProfile3DotMenu = useHasPermission([
    SCOPES.canSeeProfile3DotMenu,
  ]);
  const {
    getObjectProp,
    isPage,
    objectDetail,
    network,
    authUser,
    isAuthUser,
    checkSectionVisibility,
  } = useProfilePage();
  const visibleCoverPhoto = checkSectionVisibility('visibleCoverPhoto');
  const visibleProfilePhoto = checkSectionVisibility('visibleProfilePhoto');
  const visibleJobTitle = checkSectionVisibility('visibleJobTitle');
  const canFollowUnFollow = useHasPermission([SCOPES.canFollowUnFollow]);
  const { modals } = useProfileModals();

  const { isMoreThanTablet } = useMedia();
  const { data: aboutSectionData } = useGetAboutSectionsData();
  const { data: authUserData } = useGetAboutSectionsData({
    username: authUser?.username,
  });

  const croppedImageUrl = visibleProfilePhoto
    ? getObjectProp<string>({
        pageKey: 'croppedImageUrl',
        userKey: 'croppedImageUrl',
      })
    : '';
  const imageUrl = visibleCoverPhoto
    ? getObjectProp<string>({
        pageKey: 'imageUrl',
        userKey: 'imageUrl',
      })
    : '';
  const seoImage = applyResolutionToImageSrc(objectDetail?.imageUrl, 'medium');

  const croppedHeaderImageUrl = isPage
    ? objectDetail?.croppedHeaderImageUrl || ''
    : aboutSectionData?.croppedHeaderImageLink || '';
  const headerImageUrl = isPage
    ? objectDetail?.headerImageUrl || ''
    : aboutSectionData?.headerImageLink || '';

  const croppedHeaderImageDataStr = isPage
    ? objectDetail?.croppedHeaderImageData || '{}'
    : authUserData?.croppedHeaderImageData || '{}';
  const croppedImageDataStr = isPage
    ? objectDetail?.croppedImageData || '{}'
    : authUser?.croppedImageData || '{}';

  const croppedHeaderImageData = React.useMemo(
    () => JSON.parse(croppedHeaderImageDataStr),
    [croppedHeaderImageDataStr]
  );
  const croppedImageData = React.useMemo(
    () => JSON.parse(croppedImageDataStr),
    [croppedImageDataStr]
  );

  const forcePushLeft =
    [EDIT_PROFILE].includes(modals?.active as string) &&
    isShrink &&
    isMoreThanTablet;

  const exactName = getObjectProp<string>({
    pageKey: 'title',
    userKey: 'fullName',
  });
  const category = visibleJobTitle
    ? getObjectProp({
        pageKey: ['category', 'label'],
        userKey: ['occupation', 'label'],
      })
    : undefined;
  const usernameAtSign = getObjectProp<string>({
    pageKey: 'usernameAtSign',
    userKey: ['usernameAtSign'],
  });
  const username = getObjectProp<string>({
    pageKey: 'username',
    userKey: 'username',
  });

  const isLoggedIn = useAuthState('isLoggedIn');
  const visibleHeaderEditButton =
    isLoggedIn && !blockedAccount && canSeeProfile3DotMenu && !isPageCreation;

  const networkModalObject = {
    followers: network?.followersCounter,
    following: network?.followingsCounter,
    postsCounter: network?.postsCounter,
    objectId: objectDetail?.id,
    objectUsername: objectDetail?.username,
    isPage,
    network,
    objectTitle: exactName,
  } as NetworkModalObjectDataType;
  const disabled =
    blockedAccount ||
    isPrivateAccount ||
    !isLoggedIn ||
    !canFollowUnFollow ||
    !objectDetail?.username;

  const onClickPosts = () => {
    history.push(`/${username}${routeNames.feed}`);
  };
  return (
    <>
      <SEO
        title={exactName || ''}
        titleTemplate="Lobox"
        description={category || ''}
        pathname={`${appEnvironment.baseUrl}${routeNames.middleScreen.makeRoute(
          objectDetail?.id
        )}`}
        image={seoImage || ''}
      />
      <Flex
        className={cnj(
          classes.profileHeaderContainer,
          isPageCreation && classes.isPageCreation,
          showPublishPageButton && classes.showPublishPageButton
        )}
      >
        <HeaderBackground
          imgSrc={croppedHeaderImageUrl}
          originalImg={headerImageUrl}
          croppedImageData={croppedHeaderImageData}
          headerRef={headerRef}
          disabled={blockedAccount || isPrivateAccount}
          isCompany={isPageCreation || isPage}
        />
        <Flex className={classes.profileInfoContainer}>
          <Flex
            className={cnj(
              classes.userInfoWrapper,
              isPageCreation && classes.userInfoWrapperIsPageCreation,
              forcePushLeft && classes.forcePushLeft
            )}
          >
            <ProfileHeaderAvatar
              imgSrc={croppedImageUrl}
              originalImg={imageUrl}
              croppedImageData={croppedImageData}
              isCompany={isPageCreation || isPage}
              disabled={blockedAccount || isPrivateAccount}
            />
            <HeaderUserInfo
              isBusinessApp={isBusinessApp}
              exactName={exactName as string}
              job={isPage ? t(category) : category}
              networkModalObject={networkModalObject}
              usernameAtSign={usernameAtSign as string}
              disabled={disabled}
              onClickPosts={onClickPosts}
            />
            {visibleHeaderEditButton && (
              <HeaderEditButton
                {...{
                  isPageCreation,
                  isPrivateAccount,
                  basename,
                  isAuthUser,
                }}
              />
            )}
          </Flex>
        </Flex>

        <PermissionsGate scopes={[SCOPES.canSeeProfileHeaderRightActions]}>
          <Media lessThan="midDesktop">{actionButtons as any}</Media>
        </PermissionsGate>
      </Flex>
    </>
  );
};

export default ProfileHeader;
