import classes from './NavLink.module.scss';

import React from 'react';
import Link from '../Link';
import cnj from '../utils/cnj';
import Badge from '../Badge';
import { usePathname } from 'next/navigation';
import useCssVariables from '@shared/hooks/useCssVariables';

interface NavLinkProps {
  title?: string;
  path: string;
  exact?: boolean;
  width?: number;
  className?: string;
  disabled?: boolean;
  isFullWidth?: boolean;
  isBadge?: boolean;
  badge?: string;
  activeClassName?: string;
  isFirstLink?: boolean;
  onClick?: (e: any) => void;
}

const NavLink: React.FC<NavLinkProps> = (
  {
    title,
    path,
    exact,
    width,
    className,
    activeClassName,
    disabled,
    isFullWidth,
    isBadge,
    badge,
    isFirstLink = true,
    onClick,
  },
  ref: any
) => {
  const pathname = usePathname();

  const isActive = exact ? pathname === path : pathname?.includes(path);

  const _activeClassName = isBadge
    ? classes.activeBadgeLink
    : classes.activeLink;

  const styles = useCssVariables({
    scope: classes.linkRoot,
    variables: {
      width: `${width}px`,
    },
  });

  return (
    <>
      {styles}
      <Link
        to={path}
        onClick={onClick}
        className={cnj(
          classes.linkRoot,
          isFullWidth && classes.fullWidthLink,
          disabled && classes.disabledLink,
          isBadge ? classes.badgeLink : classes.underLinLink,
          !!badge && classes.badgeWrapper,
          isActive && _activeClassName,
          isFirstLink && classes.isFirstLink,
          className,
          isActive && activeClassName
        )}
        data-active={isActive ?? 'true'}
      >
        {badge && (
          <Badge
            className={classes.badge}
            textProps={{ color: 'brand' }}
            text={badge}
            borderColor="brand_10"
            background="brand_10"
          />
        )}
        {badge ? title : <span className={classes.title}>{title}</span>}
      </Link>
    </>
  );
};

export default React.forwardRef(NavLink);
